<template lang="pug">
    .about-page
        .banner.d-flex.justify-center.align-center(:style="{ backgroundImage: `url(${about})` }")
            h1.text-h3.white--text.orange--text.text--darken-3.font-weight-bold ABOUT US
        .main
            section#companyInfo.pa-sm-5.pa-md-10
                v-row.ma-0
                    v-col.px-md-10.d-flex.align-center(
                        :cols="12"
                        :lg="6"
                    )
                        v-img(
                            :src="about2"
                            :lazySrc="about2"
                        )
                    v-col.px-10.px-md-10(
                        :cols="12"
                        :lg="6"
                    )
                        h1.text-center Company Profile
                        p.description
                            strong BRISK SERVICES
                            |  - Your Trusted Business Partner in the Time Essential general marine ship chandlery and services last-mile fulfillment marketplace.
                        p.description
                            strong Marine never sleeps
                            |  - Brisk focus on our Customers. We go the extra mile to provide prompt and comprehensive one-stop marine solutions in this industry where time saved can quickly provide additional revenue opportunities for our Customers.
                        p.description
                            strong Brisk's win-win value proposition
                            |  - To plan ahead to always provide fast response and realistic pricing to have the opportunity to execute order fulfillment in timely and reliable fashion - Keep it simple process allows our customers to focus on their core business activities.
                        p.description
                            strong Personal touch
                            |  - Brisk Services' believes in developing endearing relationships with all stakeholders (including fellow service providers) in the marine value chain in transparent collaboration - Quick response to a wide array of value-added solutions that our Customers will need from time-to-time.
            section.pa-5.pa-sm-5.pa-md-10.blue-grey.lighten-4
                v-row.ma-0.pa-0
                    v-col.pa-0.px-sm-10.px-md-16.d-flex.justify-center.align-center(:cols="12")
                        div
                            h1.text-center Reaching Us
                            v-list.blue-grey.lighten-4
                                v-list-item.text-sm-h6 Company：BRISK Services Pte Ltd
                                //- v-list-item.text-sm-h6 公司時間：民國90年8月9日
                                v-list-item.text-sm-h6.break-word
                                    span.info-title Address：
                                    span 2 Buroh Crescent, ACE @ Buroh, #01-16
                                v-list-item.text-sm-h6(href="tel:+656677-1122") TEL： +65 6677 1122 , +65 6677 5599
                                v-list-item.text-sm-h6(href="tel:0989-131-881") Ops 24/7： +65 9732 9333
                                v-list-item.text-sm-h6(href="tel:0986-102-007") Project： +65 8800 8088
                                v-list-item.text-sm-h6(href="mailto:brisk@gmail.com") E-mail：sales@brisks.com.sg
            section#feature
                v-parallax(
                    dark
                    height="550"
                    :src="feature"
                )
                    .text-block.pa-5.grey
                        h1.text-center We believe
                        hr.diver
                        p.description
                            strong Change is the only constant thing
                            |  - Brisk focus on business sustainability through excellence in supplies and services where Safety must come first. This guiding principle allows us to grow alongside our Customers to remain relevant in this evolving marketplace.
</template>

<script>
import Title from "@/components/Title.vue";

import about from "@/assets/about.jpg";
// import about2 from "@/assets/about/about-2.png";
// import feature from "@/assets/about/feature.jpg";

// --- Brisk
import feature from '@/assets/brisk/feature.jpg';
import about2 from '@/assets/brisk/about-2.jpg';

export default {
    components: {
        Title,
    },
    data() {
        return {
            about,
            slide: 0,
            feature,
            about2,
        };
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/mediaQuery.scss';

#companyInfo {
    .description {
        font-size: 1.3rem;
        line-height: 2rem;
        font-weight: 400;
    }
}
#feature {
    .v-parallax {
        color: black;
        font-weight: bold;
    }
    .text-block {
        width: 75%;
        margin: 0 auto;
        opacity: .85;
        font-size: 1.2em;
    }
    .description {
        font-size: 1.6rem;
        line-height: 2.5rem;
    }
}
hr {
    margin: -0.8rem auto 1.5rem;
    width: 40%;
}

@include MQ(m320) {
    #feature {
        .description {
            font-size: 1rem;
        }
    }
}

@include MQ(mobile) {
    .break-word {
        display: flex;
        align-items: flex-start;

        .info-title {
            min-width: 80px;
        }
    }
    #feature {
        .text-block {
            width: 95%;
        }
        .description {
            font-size: 1.2rem;
        }
    }
    hr {
        width: 95%;
    }
}

@include MQ(tablet) {
    .break-word {
        display: flex;
        align-items: flex-start;

        .info-title {
            min-width: 105px;
        }
    }
}
</style>
