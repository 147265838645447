<template lang="pug">
    .mb-10.d-flex.align-center(:class="`justify-${justify}`")
        v-icon(
            large
            :color="`${iconColor}`"
        ) {{ leftIcon }}
        span.text-h4.font-weight-bold.mx-5 {{ title }}
        v-icon(
            large
            :color="`${iconColor}`"
        ) {{ rightIcon }}
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        leftIcon: {
            type: String,
            default: 'fa fa-caret-right'
        },
        rightIcon: {
            type: String,
            default: 'fa fa-caret-left'
        },
        justify: {
            type: String,
            default: 'center'
        },
        iconColor: {
            type: String,
            default: '#EF6C00'
        }
    }
}
</script>

<style lang="scss" scoped>

</style>